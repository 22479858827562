<script setup>
import { defineProps, toRefs, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();
const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
  checklist: {
    type: Object,
    required: true,
  },
});

const { question } = toRefs(props);

const answer = ref(true);
const comment = ref("");
const storeAnswer = async (value) => {
  const response = await store.dispatch(
    "MAgeneralModule/storeInductionResultSingleQuestion",
    {
      induction_schedule: Number(route.params.inductionId),
      induction_checklist: props.checklist.id,
      question: props.question.id,
      completed: value ? 1 : 0,
      ...(!value && { comment: comment.value }),
    }
  );
  if (response) {
    console.log(response);
    answer.value = value;
  }
};
</script>
<template>
  <div class="w-1/2">
    <base-button @click="storeAnswer(true)">yes</base-button>
    <base-button @click="answer = false" mode="cancel">No</base-button>
  </div>
  <div class="w-1/2 border-l border-gray-500" v-if="!answer">
    <div class="flex justify-between items-center">
      <label class="ml-2" :for="`reason${question.id}`"
        >justification/reasoning</label
      >
      <base-button @click="storeAnswer(false)">Save</base-button>
    </div>

    <textarea
      v-model="comment"
      name="reason"
      :id="`reason${question.id}`"
      class="w-full ml-2 border border-gray-300"
    ></textarea>
  </div>
</template>