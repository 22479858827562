<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import InductionPolicySingleQuestion from "@/components/ui/MA/inductionQuestion.vue";
import Swal from "sweetalert2";

const store = useStore();
const router = useRouter();
const route = useRoute();

onMounted(async () => {
  await store.dispatch("MAgeneralModule/fetchSingleInduction", {
    induction: route.params.inductionId,
  });
});
const colorCodeGen = () => {
  return Math.floor(Math.random() * 16777215).toString(16);
};
const currentInduction = computed(
  () => store.getters["MAgeneralModule/getCurrentInduction"]
);
const changeRoute = async () => {
  await store.dispatch("MAgeneralModule/updateScheduledInduction", {
    induction: Number(route.params.inductionId),
    is_completed: 1,
  });
  Swal.fire({
    title: "Done",
    text: "Induction Conducted Successfully",
    icon: "success",
  });
  router.push({ name: "ma-staff-induction" });
};
const goBack = () => {
  router.push({ name: "ma-staff-induction" });
};
</script>
<template>
  <div class="w-full h-full flex flex-col">
    <div class="text-teal font-medium p-4 flex cursor-pointer" @click="goBack">
      <span class="material-icons-outlined"> arrow_back_ios </span>back to
      Inductions
    </div>
    <div class="flex justify-center">
      <div class="text-2xl font-medium text-gray-600" v-if="currentInduction">
        {{
          currentInduction.user?.profile.first_name +
          " " +
          currentInduction.user?.profile.last_name
        }}
        Induction
      </div>
    </div>
    <template v-if="currentInduction && currentInduction.induction_checklists">
      <div
        class="flex items-center"
        v-for="item in currentInduction.induction_checklists"
        :key="item.id"
      >
        <div class="mr-3 w-64 text-center">
          {{ item.name }}
        </div>
        <div
          class="border-l-4 my-5 px-5 flex-grow"
          :style="`border-color:#${colorCodeGen()}`"
        >
          <div
            class="
              bg-gray-100
              w-full
              rounded-md
              border border-gray-200
              my-2
              p-4
            "
            v-for="question in item.induction_questions"
            :key="question.id"
          >
            {{ question.question }}
            <div class="flex">
              <InductionPolicySingleQuestion
                :question="question"
                :checklist="item"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <p>no induction found against ID: {{ route.params.inductionId }}</p>
    </template>
    <div class="flex justify-end mt-auto">
      <base-button @click="changeRoute">Submit</base-button>
    </div>
  </div>
</template>